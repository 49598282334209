import './index.pcss';

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import bemCnFast from 'bem-cn-fast';

const bemCn = bemCnFast('wt-layout');

const Layout = ({ className, withSidebar, tag, ...restProps }) => {
  const Tag = tag;

  return (
    <Tag
      data-test={'layout'}
      {...restProps}
      className={cn(bemCn({ 'with-sidebar': withSidebar }), className)}
    />
  );
};

Layout.propTypes = {
  tag: PropTypes.string,
  withSidebar: PropTypes.bool
};

Layout.defaultProps = {
  tag: 'div',
  withSidebar: false
};

export default Layout;

export const LayoutHeader = ({ className, tag, ...restProps }) => {
  const Tag = tag;

  return (
    <Tag
      data-test={'layout-header'}
      {...restProps}
      className={cn(bemCn('header'), className)}
    />
  );
};

LayoutHeader.propTypes = {
  tag: PropTypes.string
};

LayoutHeader.defaultProps = {
  tag: 'header'
};

export const LayoutContent = ({ className, tag, ...restProps }) => {
  const Tag = tag;

  return (
    <Tag
      data-test={'layout-content'}
      {...restProps}
      className={cn(bemCn('content'), className)}
    />
  );
};

LayoutContent.propTypes = {
  tag: PropTypes.string
};

LayoutContent.defaultProps = {
  tag: 'main'
};

export const LayoutFooter = ({ className, tag, ...restProps }) => {
  const Tag = tag;

  return (
    <Tag
      data-test={'layout-footer'}
      {...restProps}
      className={cn(bemCn('footer'), className)}
    />
  );
};

LayoutFooter.propTypes = {
  tag: PropTypes.string
};

LayoutFooter.defaultProps = {
  tag: 'footer'
};

export const LayoutSidebar = ({ className, tag, ...restProps }) => {
  const Tag = tag;

  return (
    <Tag
      data-test={'layout-sidebar'}
      {...restProps}
      className={cn(bemCn('sidebar'), className)}
    />
  );
};

LayoutSidebar.propTypes = {
  tag: PropTypes.string
};

LayoutSidebar.defaultProps = {
  tag: 'aside'
};
