import React from 'react';
import PropTypes from 'prop-types';

import { ThemeContext } from './parts/theme-context';

const ThemeProvider = ({ theme, children }) => (
  <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
);

ThemeProvider.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark'])
};

export default ThemeProvider;
