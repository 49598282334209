
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import bemCnFast from 'bem-cn-fast';

import './index.pcss';

const bemCn = bemCnFast('wt-icon');

export const Size = {
  "xs": "xs","s": "s","m": "m","l": "l"
};

const LoadingIcon = React.forwardRef(
  ({ theme, size, className, ...props }, ref) => {if (size === Size['xs']) { return (<svg id="svg6" viewBox="0 0 16 16" {...props} className={cn(bemCn({theme, size}), className)} ref={ref}><style>{"@keyframes wt-icon-rotate{to{transform:rotate(360deg)}}"}</style><path d="M8 1.5A6.507 6.507 0 0 0 1.5 8h1.114A5.386 5.386 0 1 1 8 13.386V14.5a6.5 6.5 0 1 0 0-13z" style={{
    animation: "wt-icon-rotate .7s linear infinite",
    transformOrigin: "center center"
  }} /></svg>); }
return (<svg viewBox="0 0 24 24" {...props} className={cn(bemCn({theme, size}), className)} ref={ref}><style>{"@keyframes wt-icon-rotate{to{transform:rotate(360deg)}}"}</style><path d="M12 3.25A8.76 8.76 0 0 0 3.25 12h1.5A7.25 7.25 0 1 1 12 19.25v1.5a8.75 8.75 0 1 0 0-17.5z" style={{
    animation: "wt-icon-rotate .7s linear infinite",
    transformOrigin: "center center"
  }} /></svg>);}
);

LoadingIcon.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf(["light", "dark"]),
  size: PropTypes.oneOf(["xs", "s", "m", "l"])
};

LoadingIcon.defaultProps = {
  size: "m"
};

export default LoadingIcon;