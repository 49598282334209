import './index.pcss';

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Container = ({ className, tag, ...restProps }) => {
  const Tag = tag;

  return <Tag {...restProps} className={cn('wt-container', className)} />;
};

Container.propTypes = {
  tag: PropTypes.string
};

Container.defaultProps = {
  tag: 'div'
};

export default Container;
