import './index.pcss';

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const COL_PREFIX = 'wt-col';

export const ALIGN_SELF = ['start', 'center', 'end', 'stretch'];

const Col = ({ className, tag, span, offsetLeft, alignSelf, ...restProps }) => {
  const classNameMods = {
    [`wt-offset-left-${offsetLeft}`]: !!offsetLeft,
    [`${COL_PREFIX}_align-self_${alignSelf}`]: !!alignSelf,
    [`${COL_PREFIX}-${span}`]: !!span
  };

  const Tag = tag;

  return <Tag {...restProps} className={cn(classNameMods, className)} />;
};

Col.propTypes = {
  span: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf(['inline', 'auto-fill', 'break'])
  ]).isRequired,
  tag: PropTypes.string,
  offsetLeft: PropTypes.number,
  alignSelf: PropTypes.oneOf(ALIGN_SELF)
};

Col.defaultProps = {
  tag: 'div'
};

export default Col;
