import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  LayeringContext,
  withLayering,
  LayeringContextValue
} from './parts/layering-context';

class LayeringProvider extends Component {
  static propTypes = {
    baseLayer: PropTypes.oneOf(['popup', 'dropdown', 'tooltip']).isRequired,
    children: PropTypes.node
  };

  constructor(props) {
    super(props);
    this.state = { contextValue: new LayeringContextValue(0) };
  }

  static getDerivedStateFromProps(nextProps) {
    // eslint-disable-next-line default-case
    switch (nextProps.baseLayer) {
      case 'popup':
        return {
          // eslint-disable-next-line react/prop-types
          contextValue: new LayeringContextValue(nextProps.popupZIndex, true)
        };
      case 'dropdown':
        return {
          // eslint-disable-next-line react/prop-types
          contextValue: new LayeringContextValue(nextProps.dropdownZIndex, true)
        };
      case 'tooltip':
        return {
          // eslint-disable-next-line react/prop-types
          contextValue: new LayeringContextValue(nextProps.tooltipZindex, true)
        };
    }
    return {};
  }

  render() {
    return (
      <LayeringContext.Provider value={this.state.contextValue}>
        {this.props.children}
      </LayeringContext.Provider>
    );
  }
}

export default withLayering(LayeringProvider);
