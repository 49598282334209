import './index.pcss';

import React from 'react';
import PropTypes from 'prop-types';
import bemCnFast from 'bem-cn-fast';
import cn from 'classnames';

import { GUTTER_HORIZONTAL_KEYS } from './config';

export const JUSTIFY = [
  'start',
  'center',
  'end',
  'around',
  'between',
  'evenly'
];
export const ALIGN_ITEMS = ['start', 'center', 'end', 'stretch', 'baseline'];
export const DIRECTION = ['row', 'row-reverse', 'column', 'column-reverse'];

const bemCn = bemCnFast('wt-row');

const Row = ({
  className,
  tag,
  wide,
  wrap,
  direction,
  alignItems,
  size,
  justify,
  ...restProps
}) => {
  const classNameMods = bemCn({
    size,
    wide,
    wrap,
    direction,
    justify,
    inline: !wide,
    nowrap: !wrap,
    'align-items': alignItems
  });

  const Tag = tag;

  return <Tag {...restProps} className={cn(classNameMods, className)} />;
};

Row.propTypes = {
  tag: PropTypes.string,
  wide: PropTypes.bool,
  wrap: PropTypes.bool,
  direction: PropTypes.oneOf(DIRECTION),
  alignItems: PropTypes.oneOf(ALIGN_ITEMS),
  justify: PropTypes.oneOf(JUSTIFY),
  size: PropTypes.oneOf(GUTTER_HORIZONTAL_KEYS)
};

Row.defaultProps = {
  tag: 'div',
  wide: true,
  wrap: true,
  size: 'm'
};

export default Row;
