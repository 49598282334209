import React, { cloneElement } from 'react';
import bemCnFast from '@webteam/bem-cn-fast';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { withTheme } from '@webteam/ui-contexts';
import LoadingIcon from '@webteam/icons/lib/loading';

import localNames from './index.pcss';

/**
 *  @name Button
 * @description Provides styled buttons.
 */
const bemCn = bemCnFast('wt-button', localNames);

// eslint-disable-next-line react/display-name,complexity
export const Button = React.forwardRef((props, ref) => {
  const {
    className,
    children,
    href,
    type,
    disabled,
    size,
    theme,
    icon,
    iconPosition,
    narrow,
    busy,
    flat,
    notFocusable,
    /* @private */
    highlighted /* supported only for nude mode */,
    ...restPropsWithMode
  } = props;

  // eslint-disable-next-line prefer-const
  let { mode, ...restProps } = restPropsWithMode;

  let resolvedType;
  let ButtonTag;
  if (notFocusable) {
    if (href) {
      console.error(
        'Button with `href` can\'t be completely not focusable. If you want to remove Button with `href` from focus flow, use `tabIndex="-1"` instead of `notFocusable`'
      );
    }
    /** use <div> tag for preventing button controls elements behavior for example 'space' button press support. */
    ButtonTag = 'div';
  } else {
    ButtonTag = href ? 'a' : 'button';
    resolvedType = type;
  }

  // TODO: remove in 5.0 version
  if (mode === 'black') {
    // eslint-disable-next-line no-const-assign
    mode = 'contrast';
    console.warn('Button mode `black` deprecated use `contrast` instead');
  }

  return (
    <ButtonTag
      data-test={'button'}
      {...restProps}
      ref={ref}
      href={href}
      type={resolvedType}
      disabled={disabled}
      className={cn(
        bemCn({
          disabled,
          narrow,
          mode,
          size,
          theme,
          flat,
          highlighted,
          'with-icon': !!icon,
          // check for ignored jsx values and empty string, more info https://reactjs.org/docs/jsx-in-depth.html#booleans-null-and-undefined-are-ignored
          'without-text':
            children === null ||
            children === undefined ||
            children === false ||
            children === true ||
            children === '',
          'align-icon': iconPosition,
          busy
        }),
        className
      )}
    >
      {icon &&
        cloneElement(icon, {
          className: cn(icon.props.className, bemCn('icon'))
        })}
      {busy && (
        <LoadingIcon
          className={bemCn('busy-icon')}
          size={size}
          data-test="busy-icon"
        />
      )}
      {children}
    </ButtonTag>
  );
});

Button.propTypes = {
  /** Additional class-name */
  className: PropTypes.string,
  /** Child element */
  children: PropTypes.node,
  /** Disabled*/
  disabled: PropTypes.bool,
  /** Url path for using component as link */
  href: PropTypes.string,
  /** Additional icon */
  icon: PropTypes.node,
  /** The side to place the icon */
  iconPosition: PropTypes.oneOf(['left', 'right']),
  /** Size */
  size: PropTypes.oneOf(['m', 's', 'xs']),
  /** Appearance mode @see Button.MODE */
  mode: PropTypes.oneOf([
    'primary',
    'transparent',
    'outline',
    'nude',
    'contrast'
  ]),
  /** Appearance theme */
  theme: PropTypes.oneOf(['light', 'dark']),
  /** [More about button types](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#attr-type) */
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  /** Click event handler */
  onClick: PropTypes.func,
  /** Reduce horizontal padding. __Use only when providing external width (using className, flexbox, style={{width: 200px}}, etc.)__ */
  narrow: PropTypes.bool,
  /** Shows loading icon, use when some action related to button is in progress */
  busy: PropTypes.bool,
  /**
   * Remove focus ability completely.
   * We use separate property instead of tabIndex
   * because tabIndex doesn't remove some controls behavior
   * for example 'space' button press support.
   * So with special property we can do it.
   * */
  /** @ignore */
  notFocusable: PropTypes.bool,
  /** @ignore */
  flat: PropTypes.oneOf(['left', 'right']),
  /** @ignore */
  highlighted: PropTypes.bool
};

Button.defaultProps = {
  type: 'button',
  mode: 'primary',
  size: 'm',
  iconPosition: 'left',
  busy: false
};

export default withTheme(Button);
