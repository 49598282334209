import './index.pcss';

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import bemCnFast from 'bem-cn-fast';

const bemCn = bemCnFast('wt-section');
export const BACKGROUNDS = ['white', 'gray-light', 'gray-dark', 'dark'];

const Section = ({ className, tag, background, ...restProps }) => {
  const classNameMods = bemCn({
    bg: background,
    theme:
      background === 'dark' || background === 'gray-dark' ? 'dark' : 'light'
  });
  const Tag = tag;

  return <Tag {...restProps} className={cn(classNameMods, className)} />;
};

Section.propTypes = {
  tag: PropTypes.string,
  background: PropTypes.oneOf(BACKGROUNDS)
};

Section.defaultProps = {
  tag: 'section',
  background: 'white'
};

export default Section;
