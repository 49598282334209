import './index.pcss';

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import bemCnFast from 'bem-cn-fast';
import { withTheme } from '@webteam/ui-contexts';

const bemCn = bemCnFast('wt-hr');

const Hr = ({ className, theme, ...restProps }) => {
  const classNameMods = bemCn({
    theme
  });

  return <hr {...restProps} className={cn(classNameMods, className)} />;
};

Hr.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark'])
};

Hr.defaultProps = {
  theme: 'light'
};

export default withTheme(Hr);
